import { LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';

import { router } from './router';

import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './components/config';

import './pages/app-home';
import './styles/global.css';

@customElement('app-index')
export class AppIndex extends LitElement {
  @property({ type: Boolean }) _user = true;
  @property({ type: String }) email = '';

  firstUpdated() {
    router.addEventListener('route-changed', () => {
      if ("startViewTransition" in document) {
        (document as any).startViewTransition(() => this.requestUpdate());
      }
      else {
        this.requestUpdate();
      }
    });
  }

  updated() {
    onAuthStateChanged(auth, (user) => {
      when(
        user,
        () => ((this._user = true), (this.email! = user!.email || '')),
        () => (this._user = false)
      );
    });
  }

  render() {
    return router.render();
  }
}
