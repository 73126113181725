// docs for router https://github.com/thepassle/app-tools/blob/master/router/README.md

import { html } from 'lit';

import { Router } from '@thepassle/app-tools/router.js';
import { lazy } from '@thepassle/app-tools/router/plugins/lazy.js';

import './pages/app-home.js';

const baseURL: string = (import.meta as any).env.BASE_URL;

export const router = new Router({
    routes: [
      {
        path: resolveRouterPath(),
        title: 'Home',
        render: () => html`<app-home></app-home>`
      },
      {
        path: resolveRouterPath('vakinhoudelijke-begeleiding-machinist'),
        title: 'Vakinhoudelijk begeleiding machinist',
        plugins: [
          lazy(() => import('./pages/begeleidingen/begeleiding-machinist.js')),
        ],
        render: () => html`<begeleiding-machinist></begeleiding-machinist>`
      }
    ]
  });

  // This function will resolve a path with whatever Base URL was passed to the vite build process.
  // Use of this function throughout the starter is not required, but highly recommended, especially if you plan to use GitHub Pages to deploy.
  // If no arg is passed to this function, it will return the base URL.

  export function resolveRouterPath(unresolvedPath?: string) {
    var resolvedPath = baseURL;
    if(unresolvedPath) {
      resolvedPath = resolvedPath + unresolvedPath;
    }

    return resolvedPath;
  }
