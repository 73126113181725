import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { resolveRouterPath } from '../router';

import { styles } from '../styles/shared-styles';

import { iconBahn } from '../components/shared/form-icons';

@customElement('app-home')
export class AppHome extends LitElement {
  static styles = [
    styles,
    css`
      .column-grid {
        display: grid;
        grid-template-columns: 100%;
        min-height: 300px;
      }

      .column-grid > *:first-child {
        grid-row-start: span 4;
      }

      .column-grid > *:nth-child(1) {
        background-color: var(--db-color-cool-gray-100);
      }

      .column-grid > *:nth-child(2) {
        background-color: var(--db-color-blue-100);
      }

      .column-grid > *:nth-child(3) {
        background-color: var(--db-color-red-100);
      }

      .column-grid > *:nth-child(4) {
        background-color: var(--db-color-green-100);
      }

      .column-grid > *:nth-child(5) {
        background-color: var(--db-color-orange-100);
      }
      .section-wrapper {
        display: flex;
      }

      .section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 16px;
        text-decoration: none;
        background-color: #eee;
      }

      .section-title {
        display: flex;
        flex: 1;
        align-items: center;
        margin: 0 auto;
        text-align: center;
      }

      .section-title:first-child {
        display: block;
        align-items: inherit;
        margin: inherit;
        text-align: inherit;
      }

      .section-title:first-child svg {
        fill: var(--db-color-red-500);
      }

      .section-description {
        display: flex;
        justify-content: center;
        color: var(--color-text-primary);
        font-size: 16px;
      }

      @media (min-width: 1441px) {
        .app-container {
          max-width: 1440px;
          margin: auto;
        }
      }

      @media (min-width: 601px) {
        .column-grid {
          grid-template-columns: 50% 50%;
        }
        .section-title {
          display: none;
        }
      }
      @media (min-width: 801px) {
        .column-grid {
          grid-template-columns: 50% 25% 25%;
        }

        .column-grid > *:first-child {
          grid-row-start: span 2;
        }
      }
    `,
  ];

  render() {
    return html`
      <div class="column-grid">
        <div class="section" href="#">
          <div class="section-title">${iconBahn}</div>
          <div class="section-description">Welkom bij Standards & Education</div>
        </div>
        <a class="section" href="${resolveRouterPath('vakinhoudelijke-begeleiding-machinist')}">
          <div class="section-title"></div>
          <div class="section-description">Formulieren</div>
        </a>
        <a class="section" href="#">
          <div class="section-title"></div>
          <div class="section-description">section</div>
        </a>
        <a class="section" href="#">
          <div class="section-title"></div>
          <div class="section-description">section</div>
        </a>
        <a class="section" href="#">
          <div class="section-title"></div>
          <div class="section-description">section</div>
        </a>
      </div>
    `;
  }
}
