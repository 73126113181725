/**
 * @license
 * Copyright Jurgen Knops
 * https://www.jurgenknops.nl */

import { html } from 'lit';

export const iconBahn = html`<svg width="48px" viewBox="0 0 100 70"><path d="M90,0H10.05A10,10,0,0,0,0,10v50A10.05,10.05,0,0,0,10.05,70H90a10.05,10.05,0,0,0,10-10.07V10A10,10,0,0,0,90,0ZM92.7,59.93A2.76,2.76,0,0,1,90,62.79H10.05A2.76,2.76,0,0,1,7.3,59.93V10A2.76,2.76,0,0,1,10.05,7.1H90A2.76,2.76,0,0,1,92.7,10Z"/><path d="M30.58,57.91H13.76V12.09H30.58c11.85,0,18.4,7.42,18.4,22.7C49,48.05,44.55,57.8,30.58,57.91Zm7.31-22.17c0-9.23-1-16.44-10.9-16.44H24.76V50.48h3.91C34.5,50.48,37.89,45.82,37.89,35.74Z"/><path d="M78,33.94a11.26,11.26,0,0,0,8-10.67c0-.95-.19-11.16-13.08-11.16H53.8v45.8H70c4.29,0,17.66,0,17.66-12.88C87.66,41.81,86.34,35.75,78,33.94ZM64.7,19.17h3.46c4.93,0,6.89,1.72,6.89,5.52,0,3-2.16,5.54-6,5.54H64.7Zm4.51,31.31H64.7V38.71h4.81c5.71,0,7.11,3.33,7.11,5.89C76.62,50.48,71,50.48,69.21,50.48Z"/></svg>`;
export const iconVoldoende = html`<svg slot="icon" height="24" viewBox="0 0 24 24" width="24"><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" /></svg>`;
export const iconKanBeter = html`<svg slot="icon" height="24" viewBox="0 0 24 24" width="24"><path d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z" /></svg>`;
export const iconOnvoldoende = html`<svg slot="icon" height="24" viewBox="0 0 24 24" width="24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></svg>`;
export const iconNietVanToepassing = html`<svg slot="icon" height="24" viewBox="0 0 24 24" width="24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31C15.55 19.37 13.85 20 12 20zm6.31-3.1L7.1 5.69C8.45 4.63 10.15 4 12 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z" /></svg>`;
 